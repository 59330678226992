import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import firebase from '../../../services/firebase';
import '../rig.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import loader from '../../../assets/imgs/loader.gif';
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Tabs } from 'antd';
import { addActivityLogs, isEmpty } from '../../../utils/activity-logs';
import { checkWritePermissions } from '../../../utils/native';

const { TabPane } = Tabs;
class CreateReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			id: this.props.match.params.id,
			isLoading: false,
			rig: '',
			createdBy: '',
			updatedBy: '',
			selectedTab: 1,
			initialReportOptions: ['drillingSummary', 'drillingSummaryDown', 'drillingSummaryUp', 'pusher', 'company', 'rigClock', 'tasks', 'pics', 'assets', 'equipmentInventory', 'loadSignatures'],
			losReport: {
				reportOptions: { all: true },
				title: '',
				reportType: 'los_report',
			},
			validateTrigger: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports') });
		}
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const { rigsList } = this.props.rig;
		const temp = rigsList.find((item) => item.$key === this.state.id);
		this.setState({ rig: temp }, () => {
			this.getUserById(temp.uid, 'createdBy');
			this.getUserById(temp.updatedBy, 'updatedBy');
			this.setState({ isLoading: false });
		});
	}

	getUserById(id, userType) {
		const { usersList } = this.props.auth;
		const temp = usersList.find((item) => item.uid === id);
		this.setState({ [userType]: temp });
	}

	onChange = (key) => {
		const { form } = this.props;
		this.setState({ selectedTab: parseInt(key), validateTrigger: false });
		form.resetFields();
	};

	handleOptionChange = (value) => {
		this.setState({ initialReportOptions: value });
	};

	onInputChange = (key, value) => {
		const { losReport } = this.state;
		losReport[key] = value;
		this.setState({ losReport });
	}

	onReportDateChange = (key, value) => {
		const { losReport } = this.state;
		if (value !== null) {
			losReport[key] = moment(value).format('YYYY-MM-DD');
		} else {
			losReport[key] = '';
		}
		this.setState({ losReport });
	}

	disabledDate = (current) => {
		const { losReport } = this.state;
		// Can not select days before today and today
		return moment(losReport.date) && moment(current).endOf('day') <= moment(losReport.date).endOf('day');
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { form } = this.props;
		const { losReport, initialReportOptions, rig } = this.state;

		if (initialReportOptions.length === 11) {
			losReport.reportOptions = { all: true };
		} else {
			losReport.reportOptions = initialReportOptions.reduce((obj, v) => { obj[v] = true; return obj; }, {});
		}
		form.validateFields(async (err) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				this.setState({ isLoading: true });
				losReport.generatedOn = moment().valueOf();
				losReport.uid = localStorage.getItem('user');
				losReport.createdBy = localStorage.getItem('user');
				losReport.reportType = 'los_report';
				losReport.rigId = rig.$key;
				losReport.archive = false;
				if (losReport.endDate === '') {
					delete losReport['endDate'];
				}

				const db = firebase.firestore();
				const reportsRef = db.collection('rigReports');
				const documentId = reportsRef.doc().id;
				losReport.$key = documentId;
				await reportsRef.doc(documentId).set(losReport)
					.then(() => this.updateLogs())
					.catch(() => { this.setState({ isLoading: true }); });
			}
		});
	}

	updateLogs = async() => {
		const { rig } = this.state;

		rig.updatedAt = moment().valueOf();
		rig.updatedBy = localStorage.getItem('user');
		this.setState({ isLoading: false });
		await addActivityLogs(rig, 'update', 'LOS Rig Report', rig.$key)
			.then(() => {
				alert('LOS Rig Report is created successfully');
				this.props.history.goBack();
			})
			.catch(() => { this.setState({ isLoading: true }); });
	}

	renderTabView = () => {
		const { selectedTab, validateTrigger, losReport } = this.state;
		const { form: { getFieldDecorator } } = this.props;

		return (
			<Row>
				<Col md={24} xl={12} span={24} id="DropdownFixPostion" className="left-col">
					<Form.Item label={selectedTab === 1 ? 'Date' : 'Start'}>
						{getFieldDecorator('date', {
							validateTrigger: validateTrigger ? 'onChange' : '',
							initialValue: losReport.date && losReport.date !== '' ? moment(losReport.date) : null,
							rules: [
								{ required: true, message: 'Please enter date' },
							],
						})(
							<DatePicker
								getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
								format="DD/MMM/YY"
								onChange={(e) => this.onReportDateChange('date', e)}
								style={{ width: '100%' }}
							/>
						)}
					</Form.Item>
				</Col>
				{selectedTab === 1 ?
					<Col md={24} xl={12} span={24} id="DropdownFixPostion" className="right-col">
						<Form.Item label="End">
							{getFieldDecorator('endDate', {
								validateTrigger: validateTrigger ? 'onChange' : '',
								initialValue: losReport.endDate && losReport.endDate !== '' ? moment(losReport.endDate) : null
							})(
								<DatePicker
									getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
									format="MM/DD/YYYY"
									onChange={(e) => this.onReportDateChange('endDate', e)}
									style={{ width: '100%' }}
									disabled={true}
									disabledDate={this.disabledDate}
								/>
							)}
						</Form.Item>
					</Col>
					:
					<Col md={24} xl={12} span={24} id="DropdownFixPostion">
						<Form.Item label="End">
							{getFieldDecorator('endDate', {
								validateTrigger: validateTrigger ? 'onChange' : '',
								initialValue: losReport.endDate && losReport.endDate !== '' ? moment(losReport.endDate) : null,
								rules: [
									{ required: true, message: 'Please enter date' },
								],
							})(
								<DatePicker
									getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
									format="DD/MMM/YY"
									onChange={(e) => this.onReportDateChange('endDate', e)}
									style={{ width: '100%' }}
									disabled={isEmpty(losReport.date)}
									disabledDate={this.disabledDate}
								/>
							)}
						</Form.Item>
					</Col>
				}
			</Row>
		);
	}

	render() {
		const { isLoading, rig, createdBy, updatedBy, initialReportOptions, losReport, validateTrigger } = this.state;
		const { Option } = Select;
		const { form: { getFieldDecorator } } = this.props;

		if (isLoading) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div>
				<div className="card-body">
					<Row className='rig-info-section'>
						<Col md={24} xl={12} span={24} >
							<h4 className="card-title">Rig Info</h4>
						</Col>
					</Row>
					<Row>
						<Col md={12} xl={6} span={24} >
							<p><b>Job Number: </b>{rig.jobNumber}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Name: </b>{rig.name}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Number: </b>{rig.number}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Phone: </b>{rig.phoneNumber}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Email: </b>{rig.email}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Superintendent: </b>{rig.superintendent}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Manager: </b>{rig.manager}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p>
								<b>Rig Release Date: </b>
								{rig.releaseDate &&
									<>
										<Moment format="MMM DD, YYYY">{rig.releaseDate}</Moment>&nbsp;
										<Moment format='h:mm'>{rig.releaseDate}</Moment>
									</>
								}
							</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Operator: </b>{rig.operator}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Operator Phone: </b>{rig.operatorPhone}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Operator Email: </b>{rig.operatorEmail}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Operator DSM: </b>{rig.operatorEngineer}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Move Type: </b>{rig.moveType}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Status: </b>{rig.status !== 'Canceled' ? rig.status : 'Cancelled'}</p>
						</Col>
						{createdBy ?
							<Col md={12} xl={6} span={24} >
								<p><b>Created By: </b>{createdBy.firstName + ' ' + createdBy.lastName}</p>
							</Col>
							: null}
						{updatedBy ?
							<Col md={12} xl={6} span={24} >
								<p><b>Updated By: </b>{updatedBy.firstName + ' ' + updatedBy.lastName}</p>
							</Col>
							: null}
					</Row>
				</div>
				<div className="theme-form card-body">
					<Row>
						<Col span={24}>
							<div id="DropdownFixPostion">
								<h4 className="card-title">Create LOS Rig Report</h4>
								<Select
									style={{ width: '100%' }}
									dropdownClassName="custom-select-option"
									className="custom-select-multiple"
									getPopupContainer={() => document.getElementById('DropdownFixPostion')}
									defaultValue={initialReportOptions}
									size="large"
									mode="multiple"
									onChange={(value) => this.handleOptionChange(value)}
								>
									<Option value="drillingSummary">Task/Notes Milestones - All</Option>
									<Option value="drillingSummaryDown">Task/Notes Milestones - Rig Down</Option>
									<Option value="drillingSummaryUp">Task/Notes Milestones - Rig Up</Option>
									<Option value="pusher">Truck Pusher Notes</Option>
									<Option value="company">Companies & Equipment</Option>
									<Option value="rigClock">Rig Clock</Option>
									<Option value="tasks">Non-Permit/Permit Loads - Rig Down / Rig Up</Option>
									<Option value="pics">Rig Pics</Option>
									<Option value="assets">Rig Assets</Option>
									<Option value="equipmentInventory">Equipment Inventory</Option>
									<Option value="loadSignatures">Load Signatures</Option>
								</Select>

								<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="theme-form create-report">
									<Row>
										<Col md={24} xl={12} span={24}>
											<Form.Item label="Report Title">
												{getFieldDecorator('title', {
													validateTrigger: validateTrigger ? 'onChange' : '',
													initialValue: losReport.title,
													rules: [
														{ required: true, message: 'Please enter title' }
													],
												})(
													<Input onChange={(e) => this.onInputChange('title', e.target.value)} />
												)}
											</Form.Item>
										</Col>
									</Row>
									<Tabs type="card" defaultActiveKey="1" onChange={this.onChange} className="create-report-tabs">
										<TabPane tab={'BY DATE'} key="1">
											{this.renderTabView()}
										</TabPane>
										<TabPane tab={'BY DAYS'} key="2">
											{this.renderTabView()}
										</TabPane>
									</Tabs>
									<Row>
										<Col md={24} xl={12} span={24} id="DropdownFixPostion">
											<Form.Item label="Type">
												{getFieldDecorator('type', {
													validateTrigger: validateTrigger ? 'onChange' : '',
													initialValue: losReport.type,
													rules: [
														{ required: true, message: 'Please enter report type' }
													],
												})(
													<Select
														getPopupContainer={() => document.getElementById('DropdownFixPostion')}
														size="large"
														dropdownClassName="custom-select-option"
														className="custom-select-multiple"
														onChange={(value) => this.onInputChange('type', value)}
													>
														<Option value="Safety">Safety</Option>
														<Option value="Pre-Rig Move">Pre-Rig Move</Option>
														<Option value="Observation">Observation</Option>
														<Option value="Daily">Daily</Option>
														<Option value="Final">Final</Option>
														<Option value="After Action Review">After Action Review</Option>
													</Select>
												)}
											</Form.Item>
										</Col>
										<Col md={24} xl={12} span={24}>
											<Form.Item label="onSchedule">
												{getFieldDecorator('schedule', {
													validateTrigger: validateTrigger ? 'onChange' : '',
													initialValue: losReport.schedule
												})(											
													<Checkbox className="custom-checkbox" checked={losReport.schedule} onChange={() => this.onInputChange('schedule', !losReport.schedule)}>Rig move on schedule?</Checkbox>
												)}
											</Form.Item>
										</Col>
									</Row>
									<div>
										A web link to this report will be included in resulting PDF.
										The web link has photos related to this report.
									</div>
									<Form.Item className="button-section-generate">
										<Button type="primary" htmlType="submit" size="large" className="submitBtn">
											Generate Report
										</Button>
									</Form.Item>
								</Form>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(Form.create()(CreateReport)));
